import React from "react";
import { useHistory, useParams } from "react-router-dom";
import useTranslation from "shared/hooks/useTranslation";
import useDeviceDetect from "shared/hooks/useDeviceDetect";
import { useTranslation as useLocale } from "react-i18next";

import {
  Link,
  SwipeableDrawer,
  Grid,
  AppBar,
  Toolbar,
  ToggleButton,
  ListItem,
  ToggleButtonGroup,
  IconButton,
} from "@mui/material";
import {
  Menu as MenuIcon,
  Close as CloseIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
  KeyboardArrowRight as KeyboardArrowRightIcon,
  KeyboardArrowUp as KeyboardArrowUpIcon,
  KeyboardArrowLeft as KeyboardArrowLeftIcon,
} from "@mui/icons-material";

import Logo from "assets/images/logo.png";
import Models from "components/Models/Models";
import "./Header.scss";
import Widget from "../Widget/Widget";
import {
  useQueryHeadersLinks,
  useQueryLogoData,
} from "shared/services/queries";
import { en } from "locales/translations/en";
import { ar } from "locales/translations/ar";

const models = (window.pageData && window.pageData.models) || [];

const DATA = {
  type: "models",
  list: models,
};

// const DATA = {
//   type: "models",
//   list: [
//     // these list are from pageData.models
//     {
//       type: "carModel",
//       link: "/car/atto3",
//       tabTitle: "ATTO3",
//       imageDescription: "ATTO3",
//       img: "https://byd.ps/img/b897ebd9-6fd9-4fe2-ba0f-79c8ac25ad1b/han.jpg?q=80&fit=max&crop=304%2C167%2C0%2C0",
//     },
//     {
//       type: "carModel",
//       link: "/car/Dolphin-LHD",
//       tabTitle: "DOLPHIN",
//       imageDescription: "DOLPHIN",
//       img: "https://byd.ps/img/b897ebd9-6fd9-4fe2-ba0f-79c8ac25ad1b/han.jpg?q=80&fit=max&crop=304%2C167%2C0%2C0",
//     },
//     {
//       type: "carModel",
//       link: "/car/han",
//       tabTitle: "HAN",
//       imageDescription: "HAN",
//       img: "https://byd.ps/img/b897ebd9-6fd9-4fe2-ba0f-79c8ac25ad1b/han.jpg?q=80&fit=max&crop=304%2C167%2C0%2C0",
//     },
//   ],
// };

const Header = ({ className }) => {
  const { t, i18n } = useLocale();
  const { lang } = useParams();
  const history = useHistory();
  const { isMobile } = useDeviceDetect();
  const { data: headerLinks, isError } = useQueryHeadersLinks(lang === "ar-ps");
  // const { data: logo, isError: isLogoError } = useQueryLogoData();

  const [language, setLanguage] = React.useState(lang || "en-ps");
  const [open, setOpen] = React.useState(false);
  const [navOpen, setNavOpen] = React.useState(false);

  const handleModelsOpen = (e) => {
    e.preventDefault();
    setOpen(true);
  };

  const handleModelsClose = (e) => {
    e.preventDefault();
    setOpen(false);
  };

  const handleNavToggle = () => {
    setNavOpen(!navOpen);
    setOpen(false);
  };

  const handleChange = (event, newLanguage) => {
    if (newLanguage) {
      setLanguage(newLanguage);

      window.location.pathname = history.location.pathname.replace(
        language,
        newLanguage
      );
    }
  };

  const LINKS = isError
    ? [
        {
          link: "/",
          label: t("models"),
          onMouseOver: handleModelsOpen,
          onClick: isMobile ? () => setOpen(!open) : undefined,
        },
        // {
        //   link: "/service-maintenance",
        //   label: t("service"),
        // },
        {
          link: "/news-list",
          label: t("news"),
        },
        {
          link: "/about-byd",
          label: t("about-byd"),
        },
        {
          link: "/contact-us",
          label: t("contact-us"),
        },
      ]
    : headerLinks;

  return (
    <div>
      <AppBar className="c-header" position="fixed">
        <Toolbar>
          <Grid container className="justify-content-start header-max-width">
            <Link href={`/${language}`}>
              <img
                src="https://byd.ps/img/487641c2-d960-46c0-99ea-6d42be573e85/ourlogo.png"
                alt="BYD Logo"
                className="dark:invert"
                width={104}
                height={20}
              />
            </Link>
          </Grid>
          <Grid
            container
            className="d-none d-md-flex menu-item justify-content-center"
          >
            <ul className="d-flex header-menu">
              {LINKS?.map(({ label, link, onMouseOver, isModels }) => {
                onMouseOver =
                  onMouseOver || (isModels ? handleModelsOpen : undefined);
                return (
                  <ListItem key={label}>
                    <Link
                      className="d-flex flex-nowrap"
                      onMouseOver={onMouseOver}
                      color="inherit"
                      href={`/${language}${link}`}
                      onClick={onMouseOver ? onMouseOver : undefined}
                    >
                      <span>
                        {isModels
                          ? lang === "ar-ps"
                            ? ar.translation.models
                            : en.translation.models
                          : useTranslation(label)}
                      </span>
                      {onMouseOver &&
                        (open ? (
                          <KeyboardArrowUpIcon />
                        ) : (
                          <KeyboardArrowDownIcon />
                        ))}
                    </Link>
                  </ListItem>
                );
              })}
            </ul>
          </Grid>
          <Grid container className="language-container justify-content-end">
            <ToggleButtonGroup
              color="primary"
              value={language}
              exclusive
              onChange={handleChange}
              aria-label="language"
            >
              <ToggleButton value="en-ps">EN</ToggleButton>
              <ToggleButton value="ar-ps">AR</ToggleButton>
            </ToggleButtonGroup>
            <IconButton
              className="d-flex d-md-none p-0"
              color="inherit"
              aria-label="open drawer"
              onClick={handleNavToggle}
              // edge="start"
              sx={{ ml: 3, ...(!navOpen && { display: "none" }) }}
            >
              <>{navOpen ? <CloseIcon /> : <MenuIcon />}</>
            </IconButton>
          </Grid>
        </Toolbar>
      </AppBar>
      <SwipeableDrawer
        className="Models-Drawer"
        variant="persistent"
        anchor="top"
        open={open}
        onOpen={handleModelsOpen}
        onClose={handleModelsClose}
        onMouseLeave={handleModelsClose}
      >
        {isMobile && open && (
          <IconButton
            className="d-flex d-md-none p-0"
            color="inherit"
            aria-label="close drawer"
            onClick={handleModelsClose}
            sx={{ ml: 3 }}
          >
            <KeyboardArrowLeftIcon />
          </IconButton>
        )}
        <Widget {...DATA} closeMenu={isMobile ? handleNavToggle : () => {}} />
      </SwipeableDrawer>
      <SwipeableDrawer
        anchor="right"
        open={navOpen}
        variant="persistent"
        className="Nav-Drawer"
        onOpen={handleNavToggle}
        onClose={handleNavToggle}
      >
        <Grid container className="menu-item">
          <ul className="header-menu w-100 m-3 ps-0">
            {LINKS?.map(({ label, link, onMouseOver, isModels }) => {
              onMouseOver =
                onMouseOver || (isModels ? handleModelsOpen : undefined);
              return (
                <ListItem key={label}>
                  <Link
                    onMouseOver={onMouseOver}
                    color="inherit"
                    className="d-flex w-100 justify-content-between mt-3"
                    href={`/${language}${link}`}
                    onClick={onMouseOver ? onMouseOver : undefined}
                  >
                    <span>{useTranslation(label)}</span>
                    {onMouseOver ? <KeyboardArrowRightIcon /> : null}
                  </Link>
                </ListItem>
              );
            })}
          </ul>
        </Grid>
      </SwipeableDrawer>
    </div>
  );
};

export default Header;
